import React from "react";
import axios from "axios";
import { useEffect, useState, useLayoutEffect } from "react";
import { Image } from "react-bootstrap";

function Gallery() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const [gallery, setgallery] = useState([]);

  useEffect(() => {
    getgallery();
  }, []);

  const getgallery = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/gallery/`)
        .then(function (response) {
          setgallery(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let gallery_html_data = gallery.map((gallery) => (
    <div className="col-sm-6">
      <div className="gallery-grid-item style-1">
        <Image
          className="icon-image"
          src={`.${gallery.image_path.substring(16,1000)}`}
          alt="image"
        />
        <div className="gallery-title">
          <div className="row">
            <div className="col-xs-6">
              <h4 className="h4 light col-xs-b5">{gallery.tittle}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div>
      <div>
        <div className="header-empty-space" />
        <div className="container">
          <div className="empty-space col-xs-b15 col-sm-b30" />
          <div className="breadcrumbs">
            <a href="Home">home</a>
            <a href="Gallery">gallery</a>
          </div>
          <div className="empty-space col-xs-b15 col-sm-b50 col-md-b100" />
          <div className="text-center">
            <div className="simple-article size-3 grey uppercase col-xs-b5">
              our gallery
            </div>
            <div className="h2">some our works</div>
            <div className="title-underline center">
              <span />
            </div>
          </div>
        </div>
        <div className="empty-space col-xs-b20 col-sm-b35 col-md-b70" />

        <div className="container">
          <div className="row">{gallery_html_data}</div>
        </div>
        <div className="empty-space col-xs-b20 col-sm-b35 col-md-b70" />
      </div>
    </div>
  );
}

export default Gallery;

import React from "react";
import axios from "axios";
import { useEffect, useState, useLayoutEffect } from "react";
import { Image } from "react-bootstrap";

function About() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const [about_us, setabout_us] = useState([]);

  useEffect(() => {
    getabout_us();
  }, []);

  const getabout_us = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/about_us/`)
        .then(function (response) {
          setabout_us(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let about_us_html_data = about_us.map((about_us) => (
    <div>
      <p>{about_us.content_1}</p>
      <p>{about_us.content_2}</p>
    </div>
  ));

  const [design_fan_partners, setdesign_fan_partners] = useState([]);

  useEffect(() => {
    getdesign_fan_partners();
  }, []);

  const getdesign_fan_partners = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/design_fan_partners/`)
        .then(function (response) {
          setdesign_fan_partners(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let design_fan_partners_html_data = design_fan_partners.map(
    (design_fan_partners) => (
      <div className="client-logo-entry" style={{width:'10%',margin: '10px'}}>
        <Image
          className="icon-image"
          src={`.${design_fan_partners.image_path.substring(16,1000)}`}
          alt="image"
        />
        <Image
          className="icon-image"
          src={`.${design_fan_partners.image_path.substring(16,1000)}`}
          alt="image"
        />
      </div>
    )
  );

  const [electrical_partners, setelectrical_partners] = useState([]);

  useEffect(() => {
    getelectrical_partners();
  }, []);

  const getelectrical_partners = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/electrical_partners/`)
        .then(function (response) {
          setelectrical_partners(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let electrical_partners_html_data = electrical_partners.map(
    (electrical_partners) => (
      <div className="client-logo-entry" style={{width:'10%',margin: '10px'}}>
        <Image
          className="icon-image"
          src={`.${electrical_partners.image_path.substring(16,1000)}`}
          alt="image"
        />
        <Image
          className="icon-image"
          src={`.${electrical_partners.image_path.substring(16,1000)}`}
          alt="image"
        />
      </div>
    )
  );

  const [lightning_partners, setlightning_partners] = useState([]);

  useEffect(() => {
    getlightning_partners();
  }, []);

  const getlightning_partners = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/lightning_partners/`)
        .then(function (response) {
          setlightning_partners(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let lightning_partners_partners_html_data = lightning_partners.map(
    (lightning_partners) => (
      <div className="client-logo-entry" style={{width:'10%',margin: '10px'}}>
        <Image
          className="icon-image"
          src={`.${lightning_partners.image_path.substring(16,1000)}`}
          alt="image"
        />
        <Image
          className="icon-image"
          src={`.${lightning_partners.image_path.substring(16,1000)}`}
          alt="image"
        />
      </div>
    )
  );

  return (
    <div>
      {/*eslint-disable */}
      <div
        className="block-entry fixed-background"
        style={{ backgroundImage: "url(img/Banner3.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3">
              <div className="cell-view simple-banner-height text-center">
                <div className="empty-space col-xs-b35 col-sm-b70" />
                <h1 className="h1 light">we are LIT Stores</h1>
                <div className="empty-space col-xs-b35 col-sm-b70" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="container">
        <div className="row">
          <div className="col-sm-5">
            <div className="simple-article size-3 grey uppercase col-xs-b5">
              about us
            </div>
            <div className="h2">LIT STORES</div>
          </div>
          <div className="col-sm-7">
            <div className="simple-article size-3">{about_us_html_data}</div>
          </div>
        </div>
      </div>
      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="h5 text-center">Our Vision</div>
          </div>
          <div className="col-sm-12" style={{ top: "20px" }}>
            <div className="simple-article size-3">
              Is to bring you the finest collection of interior decorations that
              cater to your unique preferences and enhance the ambiance of your
              space. With a keen focus on quality craftsmanship and innovative
              designs, we ensure that every product that leaves our store
              surpasses your expectations.
            </div>
          </div>
        </div>
      </div>
      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="h5 text-center">Our mission</div>
          </div>
          <div className="col-sm-12" style={{ top: "20px" }}>
            <div className="simple-article size-3">
              Is to bridge the gap in the market with providing both
              architectural & personal interior decor & lighting We believe in
              providing end to end solutions right from the planning stage till
              the execution stage to help the customer have a hassle free
              experience for their dream spaces.
            </div>
          </div>
        </div>
      </div>
      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="container">
        <div className="text-center">
          <div className="simple-article size-3 grey uppercase col-xs-b5">
            our team
          </div>
          <div className="h4">Chairman Message</div>
          <div className="title-underline center">
            <span />
          </div>
        </div>
      </div>
      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="container">
        <div className="slider-wrapper our-team-slider">
          <div className="swiper-button-prev hidden-xs hidden-sm" />
          <div className="swiper-button-next hidden-xs hidden-sm" />
          <div
            className="swiper-container"
            data-breakpoints={1}
            data-xs-slides={1}
            data-sm-slides={2}
            data-md-slides={3}
            data-lt-slides={4}
            data-slides-per-view={4}
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="product-shortcode style-9">
                  <div className="preview">
                    <img src="img/thumbnail-40.jpg" alt />
                  </div>
                  <div className="content">
                    <div className="title">
                      <div className="simple-article size-1 uppercase color col-xs-b5">
                        founder
                      </div>
                      <div className="h6">Naveen Kumar</div>
                    </div>
                    <div className="description" style={{ maxWidth: "550px" }}>
                      <div
                        className="simple-article text size-3"
                      >
                        I aim to create a space for contemporary, chique &
                        minamilistic interior that will provide a modern
                        approach to accentuate your spaces.
                      </div>
                    </div>
                    <div className="follow light">
                      <a className="entry" href="https://www.facebook.com/navin.gadiya">
                        <i className="fa fa-facebook" />
                      </a>
                      <a className="entry" href="https://www.instagram.com/navin_gadiya/">
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-pagination relative-pagination visible-xs visible-sm" />
          </div>
        </div>
      </div>
      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="container">
        <div className="text-center">
          <div className="simple-article size-3 grey uppercase col-xs-b5">
            our brands
          </div>
          <div className="h3">Our Design Fan Partner</div>
          <div className="title-underline center">
            <span />
          </div>
        </div>
      </div>
      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="container">{design_fan_partners_html_data}</div>

      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="container">
        <div className="text-center">
          <div className="simple-article size-3 grey uppercase col-xs-b5">
            our brands
          </div>
          <div className="h3">Our Switches And Automation Partners</div>
          <div className="title-underline center">
            <span />
          </div>
        </div>
      </div>
      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="container">{electrical_partners_html_data}</div>
      <div className="empty-space col-xs-b35 col-md-b70" />

      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="container">
        <div className="text-center">
          <div className="simple-article size-3 grey uppercase col-xs-b5">
            our brands
          </div>
          <div className="h3">Our Lighting Partners</div>
          <div className="title-underline center">
            <span />
          </div>
        </div>
      </div>
      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="container">{lightning_partners_partners_html_data}</div>
      <div className="empty-space col-xs-b35 col-md-b70" />
    </div>
  );
}

export default About;

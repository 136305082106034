import React, { useState, useLayoutEffect } from "react";
import axios from "axios";

const Contact = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    location: "",
    requirement: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE}/enquiry/`,
        formData
      );
      console.log(response.data);
      window.alert("Thanks! We will Contact You Soon");
      // Reset form data
      setFormData({
        name: "",
        phone: "",
        location: "",
        requirement: "",
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {/*eslint-disable */}
      <div
        className="block-entry fixed-background"
        style={{ backgroundImage: "url(img/Banner2.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3">
              <div className="cell-view simple-banner-height text-center">
                <div className="empty-space col-xs-b35 col-sm-b70" />
                <h1 className="h1 light">contact us</h1>
                <div className="title-underline center">
                  <span />
                </div>
                <div className="empty-space col-xs-b35 col-sm-b70" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="container">
        <div className="text-center">
          <div className="simple-article size-3 grey uppercase col-xs-b5">
            our contacts
          </div>
          <div className="h2">we ready for your questions</div>
          <div className="title-underline center">
            <span />
          </div>
        </div>
      </div>
      <div className="empty-space col-sm-b15 col-md-b50" />
      <div className="container">
        <div className="row">
          <div className="col-sm-3">
            <div className="icon-description-shortcode style-1">
              <img className="icon" src="img/icon-25.png" alt />
              <div className="title h6">address</div>
              <div className="description simple-article size-2">
                NO 10, Captain Palanisamy Layout, Thadagam Road, Coimbatore -
                641 002
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="icon-description-shortcode style-1">
              <img className="icon" src="img/icon-23.png" alt />
              <div className="title h6">phone</div>
              <div className="description simple-article size-2">
                <a href="tel:++91 70920 99667">+91 70920 99667</a>
                <br></br>
                <a href="tel:++91 99444 65350">+91 99444 65350</a>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="icon-description-shortcode style-1">
              <img className="icon" src="img/icon-28.png" alt />
              <div className="title h6">email</div>
              <div className="description simple-article size-2">
                <a href="mailto:saleslitstores@gmail.com">
                  saleslitstores@gmail.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="icon-description-shortcode style-1">
              <img className="icon" src="img/icon-26.png" alt />
              <div className="title h6">Follow us</div>
              <div className="follow light">
                <a className="entry" href="#">
                  <i className="fa fa-facebook" />
                </a>
                <a
                  className="entry"
                  href="https://www.instagram.com/litstorescbe/"
                >
                  <i className="fa fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="empty-space col-xs-b25 col-sm-b50" />
      <div className="container">
        <div className="map-wrapper">
          <div className="full-width">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.3815987614885!2d76.9404317100719!3d11.009969254798452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85977f6f80b11%3A0x6cbfb3c530b2d74d!2sLIT%20Stores!5e0!3m2!1sen!2sin!4v1683050280755!5m2!1sen!2sin"
              width="100%"
              height={450}
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
        <div className="addresses-block hidden">
          <a
            className="marker"
            data-lat="11.010111417795391"
            data-lng="76.94297981423956"
            data-string="1. Here is some address or email or phone or something else..."
          />
        </div>
      </div>
      <div className="empty-space col-xs-b25 col-sm-b50" />
      <div className="container">
        <h4 className="h4 text-center col-xs-b25">have a questions?</h4>
        <div className="row">
          <div className="col-md-10 col-md-offset-1">
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="row m5">
                <div className="col-sm-6">
                  <input
                    className="simple-input col-xs-b20"
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    className="simple-input col-xs-b20"
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    className="simple-input col-xs-b20"
                    type="text"
                    placeholder="Location"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    className="simple-input col-xs-b20"
                    type="text"
                    placeholder="Your Requirement"
                    name="requirement"
                    value={formData.requirement}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-sm-12">
                  <div className="text-center">
                    <div className="button size-2 style-3">
                      <span className="button-wrapper">
                        <span className="icon">
                          <img src="img/icon-4.png" alt="" />
                        </span>
                        <span className="text">send message</span>
                      </span>
                      <input type="submit" />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="empty-space col-xs-b35 col-md-b70" />
    </div>
  );
};

export default Contact;

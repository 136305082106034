import React from "react";
import axios from "axios";
import { useEffect, useState, useLayoutEffect } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


function Home() {
  
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    // Load external JavaScript scripts here
    const script1 = document.createElement("script");
    script1.src = "/js/global.js";
    script1.async = true;
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = "/js/swiper.jquery.min.js";
    script2.async = true;
    document.body.appendChild(script2);

    // Clean up the scripts when the component unmounts
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  const [about_us, setabout_us] = useState([]);

  useEffect(() => {
    getabout_us();
  }, []);

  const getabout_us = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/about_us/`)
        .then(function (response) {
          setabout_us(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let about_us_html_data = about_us.map((about_us) => (
    <p>{about_us.content_1}</p>
  ));

  const [our_services, setour_services] = useState([]);

  useEffect(() => {
    getour_services();
  }, []);

  const getour_services = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/our_services/`)
        .then(function (response) {
          setour_services(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let our_services_html_data = our_services.map((our_service, key) => (
    <div key={key} className="col-sm-4">
      <div className="icon-description-shortcode style-1">
        <Image
          className="icon-image"
          src={`.${our_service.image_path.substring(16,1000)}`}
          alt="image"
        />
        <div className="title h6">{our_service.heading}</div>
        <div className="description simple-article size-2">
          {our_service.description}
        </div>
      </div>
      <div className="empty-space col-xs-b40" />
    </div>
  ));

  const [banner, setbanner] = useState([]);

  useEffect(() => {
    getbanner();
  }, []);

  const getbanner = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/banner/`)
        .then(function (response) {
          setbanner(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let banner_html_data = banner.map((banner, key) => (
    <div key={key}>
      <Image
        className="responsive-image"
        src={`.${banner.image_path.substring(16,1000)}`}
        alt="image"
      />
    </div>
  ));

  const [popupOpen, setPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState(null);

  const openPopup = (data) => {
    setPopupData(data);
    setPopupOpen(true);

  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const [products, setproducts] = useState([]);

  useEffect(() => {
    getproducts();
  }, []);

  const getproducts = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/top_products/`)
        .then(function (response) {
          setproducts(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const [category, setcategory] = useState([]);

  useEffect(() => {
    getcategory();
  }, []);

  const getcategory = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/category`)
        .then(function (response) {
          setcategory(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let category_html_data = category.map((category) => (
    <li>
      <a
        className="tab-menu"
        href="Home"
        onClick={(e) => {
          e.preventDefault();
          filterResult(category.category);
        }}
      >
        {category.category}
      </a>
    </li>
  ));

  useEffect(() => {
    nonfilterResult();
    // eslint-disable-next-line
  }, [products]);

  const [data, setData] = useState(products);

  const filterResult = (category) => {
    const result = products.filter((filtered_data) => {
      return filtered_data.category === category;
    });
    setData(result);
  };

  const nonfilterResult = () => {
    setData(products);
  };

  let products_html_data = data.map((data) => (
    <div className="col-sm-4">
      <div
        className="gallery-grid-item style-2"
        onClick={() => openPopup(data)}
      >
        <Image
          className="icon-image"
          src={`.${data.img_1.substring(16,1000)}`}
          alt="image"
        />
        <div className="gallery-title">
          <div className="left">
            <div className="simple-article size-1 uppercase color col-xs-b5">
              {data.category}
            </div>
            <h6 className="h6" style={{fontFamily:'font-Awesome'}}>{data.name}</h6>
          </div>
        </div>
      </div>
      <div className="empty-space col-xs-b40" />
    </div>
  ));

  return (
    <div>
{/*       <div className="slider-wrapper">
        <div className="swiper-button-prev visible-lg" />
        <div className="swiper-button-next visible-lg" />
        <div
          className="swiper-container"
          data-parallax={1}
          data-auto-height={1}
        >
          <div className="swiper-wrapper">{banner_html_data}</div>
          <div className="swiper-pagination swiper-pagination-white" />
        </div>
      </div> */}

      <div style={{ width: "100%" }}>
        <AliceCarousel
          mouseTracking
          items={banner_html_data}
          disableDotsControls={true}
          disableButtonsControls={true}
          autoPlay={true}
          infinite={true}
          animationDuration={1000}
          autoPlayInterval={2000}
        />
      </div>

      <div className="empty-space col-sm-b15 col-md-b50" />
      <div className="empty-space col-sm-b15 col-md-b50" />

      <div className="container">
        <div className="text-center">
          <div className="simple-article size-3 grey uppercase col-xs-b5">
            Who we Are
          </div>
          <div className="h3">what we do</div>
          <div className="title-underline center">
            <span />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-sm-5">
            <div className="simple-article size-3 grey uppercase col-xs-b5">
              about us
            </div>
            <div className="h2">LIT Stores</div>
          </div>
          <div className="col-sm-7">
            <div className="simple-article size-3">
              {about_us_html_data}
              <form action="about1.html">
                <div className="button size-2 style-3">
                  <span className="button-wrapper">
                    <span className="icon">
                      <img src="img/icon-4.png" alt="" />
                    </span>
                    <span className="text">read more</span>
                  </span>
                  <input type="submit" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="empty-space col-xs-b35 col-md-b70" />

      <div className="container">
        <div className="text-center">
          <div className="simple-article size-3 grey uppercase col-xs-b5">
            our services
          </div>
          <div className="h3">Our Service Areas</div>
          <div className="title-underline center">
            <span />
          </div>
        </div>
      </div>

      <div className="empty-space col-sm-b15 col-md-b50" />

      <div className="container">
        <div className="row">{our_services_html_data}</div>
      </div>

      <div className="empty-space col-sm-b15 col-md-b50" />
      <div className="empty-space col-xs-b35 col-md-b70" />

      <div className="container">
        <div className="text-center">
          <div className="simple-article size-3 grey uppercase col-xs-b5">
            our products
          </div>
          <div className="h3">Top selling Products</div>
          <div className="title-underline center">
            <span />
          </div>
        </div>
      </div>

      <div className="empty-space col-xs-b35 col-md-b70" />

      <div className="container">
        <div className="sorting-menu tabulation-menu-wrapper text-center">
          <div className="tabulation-title simple-input">all</div>
          <ul className="tabulation-toggle">
            {/*eslint-disable */}
            <li>
              <a
                className="tab-menu active"
                onClick={() => {
                  nonfilterResult();
                }}
              >
                all
              </a>
            </li>
            {category_html_data}
          </ul>
        </div>

        <div className="empty-space col-xs-b30 col-sm-b60" />

        <div className="container">
          <div className="row">{products_html_data}</div>
        </div>
      </div>

      <div className="empty-space col-xs-b35 col-md-b70" />
      <div className="empty-space col-xs-b35 col-md-b70" />

      {popupOpen && (
        <div
          className="popup-content active"
          style={{ position: "fixed", zIndex: "20" }}
        >
          <div className="bg-layer" />
          <div className="popup-content active">
            <div className="layer-close" />
            <div className="popup-container size-3">
              <div className="popup-align">
                <div className="row">
                  <div className="col-sm-6 col-xs-b30 col-sm-b0">
                  <div className="main-product-slider-wrapper">
                    <Image
                      className="icon-image" style={{width:'100%'}}
                      src={`.${popupData.img_1.substring(16,1000)}`}
                      alt="image"
                    />
{/*                       <div className="main-image">
                        <img
                          src={`.${mainImage.substring(16,1000)}`}
                          alt=""
                          style={{ width: "280px" }}
                        />
                      </div>
                      <div
                        className="image-previews"
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "10px",
                        }}
                      >
                        {imagePreviews.map((imagePath, index) => (
                          <div className="image-preview" key={index}>
                            <img
                              src={`.${imagePath.substring(16,1000)}`}
                              alt=""
                              style={{ width: "60px", height: "60px" }}
                              onClick={() => handleImageClick(imagePath)}
                            />
                          </div>
                        ))}
                      </div> */}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="simple-article size-3 grey col-xs-b5">
                      {popupData.category}
                    </div>
                    <div className="h3 col-xs-b25" style={{fontFamily:'font-Awesome'}}>{popupData.name}</div>
                    <div className="simple-article size-3 col-xs-b30" dangerouslySetInnerHTML={{ __html: popupData.description.replace(/<\/br>/g, '<br/>') }}></div>
                    <div className="row m5 col-xs-b40">
                      <div className="col-sm-6 col-xs-b10 col-sm-b0">
                        <a
                          className="button size-2 style-2 block"
                          href="checkout1.html"
                        >
                          <Link to={`/Checkout/${popupData.id}`}>
                            <span className="button-wrapper">
                              <span className="icon">
                                <img src="img/icon-2.png" alt="" />
                              </span>
                              <span className="text">Buy Now</span>
                            </span>
                          </Link>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-close" onClick={closePopup} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;

import React, { useState, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function Header() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  const [isMenuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <header>
        <div className="header-bottom">
          <div className="content-margins">
            <div className="row">
              <div className="col-xs-3 col-sm-1">
                <Link id="logo" to="/Home">
                  <img src="img/logo-2.png" alt="" />
                </Link>
              </div>
              <div className="col-xs-9 col-sm-11 text-right">
                <div className={`nav-wrapper ${isMenuOpen ? "active" : ""}`}>
                  <div className="nav-close-layer" />
                  <nav>
                    <ul>
                      <li className={location.pathname === "/Home" ? "active" : ""}>
                        <Link to="/Home">Home</Link>
                      </li>
                      <li className={location.pathname === "/About-Us" ? "active" : ""}>
                        <Link to="/About-Us">About us</Link>
                      </li>
                      <li className={location.pathname === "/Products" ? "active" : ""}>
                        <Link to="/Products">Products</Link>
                        <ul>
                          <li className={location.pathname === "/Lighting-Products" ? "active" : ""}>
                            <Link to="/Lighting-Products">Lighting Products</Link>
                          </li>
                          <li className={location.pathname === "/Electrical-Products" ? "active" : ""}>
                            <Link to="/Electrical-Products">Electrical Products</Link>
                          </li>
                          <li className={location.pathname === "/Fan-Products" ? "active" : ""}>
                            <Link to="/Fan-Products">Fan Products</Link>
                          </li>
                        </ul>
                      </li>
                      <li className={location.pathname === "/Gallery" ? "active" : ""}>
                        <Link to="/Gallery">Gallery</Link>
                      </li>
                      <li className={location.pathname === "/Contact-Us" ? "active" : ""}>
                        <Link to="/Contact-Us">Contact us</Link>
                      </li>
                    </ul>
                    <div className="navigation-title" onClick={handleMenuToggle}>
                      Menu
                      <div className={`hamburger-icon ${isMenuOpen ? "active" : ""}`}>
                        <span />
                        <span />
                        <span />
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="col-md-7 col-md-text-right">
                <div className="hamburger-icon" onClick={handleMenuToggle}>
                  <span />
                  <span />
                  <span />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;

import {BrowserRouter as Router,Route,Routes} from 'react-router-dom'
import Header from "./components/Header";
import Footer from './components/Footer';
import Home from "./components/Home";
import About from './components/About';
import Products from './components/Products';
import Gallery from './components/Gallery';
import Checkout from './components/Checkout';
import Contact from './components/Contact';
import FanProducts from './components/FanProducts';
import LightingProducts from './components/LightingProducts';
import ElectricalProducts from './components/ElectricalProducts';

function App() {
  return (
    <div className="App">
    <Router>
    <Header></Header>
    <Routes>
      <Route exact path='/' element={<Home/>}/>
      <Route exact path='/Home' element={<Home/>}/>
      <Route exact path='/About-Us' element={<About/>}/>
      <Route exact path='/Products' element={<Products/>}/>
      <Route exact path='/Fan-Products' element={<FanProducts/>}/>
      <Route exact path='/Electrical-Products' element={<ElectricalProducts/>}/>
      <Route exact path='/Lighting-Products' element={<LightingProducts/>}/>
      <Route exact path='/Gallery' element={<Gallery/>}/>
      <Route exact path='/Checkout' element={<Checkout/>}/>
      <Route exact path='/Contact-Us' element={<Contact/>}/>
      <Route exact path='/Checkout/:id' element={<Checkout/>}/>
    </Routes>
    <Footer></Footer>
    </Router>
    </div>
  );
}

export default App;

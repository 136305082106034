import React from "react";
import axios from "axios";
import { useEffect, useState, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { Image } from "react-bootstrap";

function Checkout() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  let params = useParams();

  const [products, setproducts] = useState([]);

  useEffect(() => {
    getproducts();
    // eslint-disable-next-line
  }, []);

  const getproducts = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/products/${params.id}`)
        .then(function (response) {
          setproducts(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const [customerName, setCustomerName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      customerName,
      mobileNumber,
      email,
      location,
    };

    const orderData = {
      ...formData,
      ...products,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE}/orders/`,
        orderData
      );
      console.log(response.data);
      window.alert("Thanks! We will Contact You Soon");
      setCustomerName("");
      setMobileNumber("");
      setEmail("");
      setLocation("");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div>
        <div className="header-empty-space" />
        <div className="container">
          <div className="empty-space col-xs-b15 col-sm-b30" />
          <div className="breadcrumbs">
            <a href="Home">home</a>
            <a href="Home">checkout</a>
          </div>
          <div className="empty-space col-xs-b15 col-sm-b50 col-md-b100" />
          <div className="text-center">
            <div className="simple-article size-3 grey uppercase col-xs-b5">
              checkout
            </div>
            <div className="h2">check your information</div>
            <div className="title-underline center">
              <span />
            </div>
          </div>
        </div>
        <div className="empty-space col-xs-b35 col-md-b70" />
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-xs-b50 col-md-b0">
              <h4 className="h4 col-xs-b25">Enquiry Details</h4>
              <form onSubmit={handleSubmit}>
                <div className="row m10">
                  <div className="col-sm-12">
                    <input
                      className="simple-input"
                      type="text"
                      value={customerName}
                      placeholder="Name"
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                    <div className="empty-space col-xs-b20" />
                  </div>
                  <div className="col-sm-12">
                    <input
                      className="simple-input"
                      type="text"
                      value={mobileNumber}
                      placeholder="Mobile Number"
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                    <div className="empty-space col-xs-b20" />
                  </div>
                  <div className="col-sm-12">
                    <input
                      className="simple-input"
                      type="text"
                      value={email}
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="empty-space col-xs-b20" />
                  </div>
                </div>
                <input
                  className="simple-input"
                  type="text"
                  value={location}
                  placeholder="Location"
                  onChange={(e) => setLocation(e.target.value)}
                />
                <div className="empty-space col-xs-b20" />
                <div className="empty-space col-xs-b30 col-sm-b60" />
              </form>
            </div>
            <div className="col-md-6">
              <h4 className="h4 col-xs-b25">your product details</h4>
              <div className="cart-entry clearfix">
                <a className="cart-entry-thumbnail" href="Home">
                  <Image
                    src={
                      products.img_1
                        ? `${products.img_1.substring(16,1000)}`
                        : ""
                    }
                    alt="image"
                    style={{ width: "100px" }}
                  />
                </a>
                <div className="cart-entry-description">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div className="h6">
                            <a href="Home">{products.name}</a>
                          </div>
                          <div className="simple-article size-1">
                            {products.category}
                          </div>
                        </td>
                        <td>
                          <div
                            className="cart-color"
                            style={{ background: "#eee" }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="empty-space col-xs-b10" />

              <div className="button block size-2 style-3">
                <span className="button-wrapper">
                  <span className="icon">
                    <img src="img/icon-4.png" alt="" />
                  </span>
                  <span className="text">place order</span>
                </span>
                <input type="submit" onClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
        <div className="empty-space col-xs-b35 col-md-b70" />
      </div>
    </div>
  );
}

export default Checkout;

import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";

function Footer() {
  const [about_us, setabout_us] = useState([]);

  useEffect(() => {
    getabout_us();
  }, []);

  const getabout_us = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/about_us/`)
        .then(function (response) {
          setabout_us(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let about_us_html_data = about_us.map((about_us) => (
    <p>{about_us.content_1}</p>
  ));

  return (
    <div>
      <footer>
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-sm-6 col-md-3 col-xs-b30 col-md-b0">
                <img src="img/logo-3.png" alt="" style={{ width: "49px" }} />
                <div className="empty-space col-xs-b20" />
                <div className="simple-article size-2 light fulltransparent">
                  {about_us_html_data}
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-xs-b30 col-md-b0">
                <h6 className="h6 light">Conatct Details</h6>
                <div className="empty-space col-xs-b20" />
                <div className="footer-contact">
                  <i className="fa fa-mobile" aria-hidden="true" /> contact us:{" "}
                  <a href="tel:++9170920 99667">+91 70920 99667</a>
                </div>
                <div className="footer-contact">
                  <i className="fa fa-envelope-o" aria-hidden="true" /> email:{" "}
                  <a href="mailto:saleslitstores@gmail.com">
                    saleslitstores@gmail.com
                  </a>
                </div>
                <div className="footer-contact">
                  <i className="fa fa-map-marker" aria-hidden="true" /> address:{" "}
                  <a href="Home">
                    NO 10, Captain Palanisamy Layout, Thadagam Road, Coimbatore
                    - 641 002
                  </a>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-xs-b30 col-md-b0">
                <h6 className="h6 light">queck links</h6>
                <div className="empty-space col-xs-b20" />
                <div className="footer-column-links">
                  <div className="row">
                    <div className="col-xs-6">
                      <a href="Home">home</a>
                      <a href="About-Us">about us</a>
                      <a href="Products">products</a>
                      <a href="Gallery">gallery</a>
                      <a href="Contact">contact</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row">
              <div className="col-lg-8 col-xs-text-center col-lg-text-left col-xs-b20 col-lg-b0">
                <div className="copyright">
                  © 2023 All rights reserved. Development by{" "}
                  <a
                    href="https://dailydaysolutions.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Daily Day Solutions
                  </a>
                </div>
                <div className="follow">
                  <a
                    className="entry"
                    href="https://www.instagram.com/litstorescbe/"
                  >
                    <i className="fa fa-facebook" />
                  </a>
                  <a className="entry" href="Home">
                    <i className="fa fa-instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;

import React from "react";
import axios from "axios";
import { useEffect, useState, useLayoutEffect } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

function LightingProducts() {
  let category_type = "Lighting_Products";

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const [popupOpen, setPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState(null);

  const openPopup = (data) => {
    setPopupData(data);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const [lighting_products, setlighting_products] = useState([]);

  useEffect(() => {
    getlighting_products();
  }, []);

  const getlighting_products = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/lighting_products/`)
        .then(function (response) {
          setlighting_products(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const [category, setcategory] = useState([]);

  useEffect(() => {
    getcategory();
    // eslint-disable-next-line
  }, []);

  const getcategory = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/category/${category_type}`)
        .then(function (response) {
          setcategory(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let category_html_data = category.map((category) => (
    <li>
      <a
        className="tab-menu"
        href="Home"
        onClick={(e) => {
          e.preventDefault();
          filterResult(category.category);
        }}
      >
        {category.category}
      </a>
    </li>
  ));

  useEffect(() => {
    nonfilterResult();
    // eslint-disable-next-line
  }, [lighting_products]);

  const [data, setData] = useState(lighting_products);

  const filterResult = (category) => {
    const result = lighting_products.filter((filtered_data) => {
      return filtered_data.category === category;
    });
    setData(result);
  };

  const nonfilterResult = () => {
    setData(lighting_products);
  };

  let products_html_data = data.map((data) => (
    <div className="col-sm-4">
      <div
        className="gallery-grid-item style-2"
        onClick={() => openPopup(data)}
      >
        <Image
          className="icon-image"
          src={`.${data.img_1.substring(16,1000)}`}
          alt="image"
        />
        <div className="gallery-title">
          <div className="left">
            <div className="simple-article size-1 uppercase color col-xs-b5">
              {data.category}
            </div>
            <h6 className="h6" style={{fontFamily:'font-Awesome'}}>{data.name}</h6>
          </div>
        </div>
      </div>
      <div className="empty-space col-xs-b40" />
    </div>
  ));

  return (
    <div>
      <div>
        <div className="header-empty-space" />
        <div className="container">
          <div className="empty-space col-xs-b15 col-sm-b30" />
          <div className="breadcrumbs">
            <a href="Home">home</a>
            <a href="Products">Products</a>
          </div>
          <div className="text-center">
            <div className="simple-article size-3 grey uppercase col-xs-b5">
              our Lighting products
            </div>
            <div className="title-underline center">
              <span />
            </div>
          </div>
        </div>
        <div className="empty-space col-xs-b20 col-sm-b35 col-md-b70" />
        <div className="container">
          <div className="sorting-menu tabulation-menu-wrapper text-center">
            <div className="tabulation-title simple-input">all</div>
            <ul className="tabulation-toggle">
              {/*eslint-disable */}
              <li>
                <a
                  className="tab-menu active"
                  onClick={() => {
                    nonfilterResult();
                  }}
                >
                  all
                </a>
              </li>
              {category_html_data}
            </ul>
          </div>

          <div className="empty-space col-xs-b30 col-sm-b60" />

          <div className="container">
            <div className="row">{products_html_data}</div>
          </div>
        </div>
        <div className="empty-space col-xs-b20 col-sm-b35 col-md-b70" />
      </div>

      {popupOpen && (
        <div
          className="popup-content active"
          style={{ position: "fixed", zIndex: "20" }}
        >
          <div className="bg-layer" />
          <div className="popup-content active">
            <div className="layer-close" />
            <div className="popup-container size-3">
              <div className="popup-align">
                <div className="row">
                  <div className="col-sm-6 col-xs-b30 col-sm-b0">
                  <div className="main-product-slider-wrapper">
                    <Image
                      className="icon-image" style={{width:'100%'}}
                      src={`.${popupData.img_1.substring(16,1000)}`}
                      alt="image"
                    />
{/*                       <div className="main-image">
                        <img
                          src={`.${mainImage.substring(16,1000)}`}
                          alt=""
                          style={{ width: "280px" }}
                        />
                      </div>
                      <div
                        className="image-previews"
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "10px",
                        }}
                      >
                        {imagePreviews.map((imagePath, index) => (
                          <div className="image-preview" key={index}>
                            <img
                              src={`.${imagePath.substring(16,1000)}`}
                              alt=""
                              style={{ width: "60px", height: "60px" }}
                              onClick={() => handleImageClick(imagePath)}
                            />
                          </div>
                        ))}
                      </div> */}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="simple-article size-3 grey col-xs-b5">
                      {popupData.category}
                    </div>
                    <div className="h3 col-xs-b25" style={{fontFamily:'font-Awesome'}} >{popupData.name}</div>
                    <div className="simple-article size-3 col-xs-b30" dangerouslySetInnerHTML={{ __html: popupData.description.replace(/<\/br>/g, '<br/>') }}></div>
                    <div className="row m5 col-xs-b40">
                      <div className="col-sm-6 col-xs-b10 col-sm-b0">
                        <a
                          className="button size-2 style-2 block"
                          href="checkout1.html"
                        >
                          <Link to={`/Checkout/${popupData.id}`}>
                            <span className="button-wrapper">
                              <span className="icon">
                                <img src="img/icon-2.png" alt="" />
                              </span>
                              <span className="text">Buy Now</span>
                            </span>
                          </Link>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-close" onClick={closePopup} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LightingProducts;
